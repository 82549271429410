<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    show-expand
    item-key="id"
    sort-by="date"
    sort-desc
    class="elevation-1"
    @current-items="currentItems"
    :loading="loading"
    loading-text="Loading... Please wait"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Reports List</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="search_tool"
          v-model="search"
          ref="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          clearable
        ></v-text-field>

        <!-- Filtros -->
        <v-dialog v-if="search_tool" v-model="dialogFilters" max-width="400px">
          <template v-slot:activator="{ on }">
            <!--<v-btn
              class="ml-4"
              color="blue-grey"
              fab
              small
              dark
              v-on="on"
              @click="dialogFilters = true"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>-->
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Advanced Search</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      :items="filters"
                      v-model="filters.text"
                      label="Filters"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-menu
                      ref="rangeCalendar"
                      v-model="rangeCalendar"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-x
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dateRangeText"
                          label="Dates"
                          hint="Enter a date range range. Separate with '~'"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="rangeDates" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="rangeDates = []">Clear</v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.rangeCalendar.save(rangeDates);"
                        >OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogFilters = false">Close</v-btn>
              <v-btn color="blue darken-1" text @click="filterItems(filters.text)">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Fin Filtros -->

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <template v-slot:activator="{ on }">
            <v-btn 
              color="primary" 
              dark 
              class="mb-2" 
              v-on="on" 
              @click="formNewItem()"
            >New Report</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row v-if="formNewtItem" class="form-first-field">
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      prepend-icon="folder"
                      placeholder="Select Work Order"
                      :items="workOrdersList"
                      item-text="id"
                      item-value="id"
                      label="Work Order"
                      v-model="current_wo_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="popDatepick1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="editedItem.date"
                          label="Date"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.date"
                        @input="popDatepick1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.description"
                      label="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                  <!--<v-col v-if="formNewtItem" cols="12" sm="12" md="12">-->
                    <v-file-input
                      v-model="editedItem.attachment"
                      type="file"
                      color="primary"
                      counter
                      label="Attachment"
                      multiple
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="primary" dark label small>{{ text }}</v-chip>
                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >+{{ editedItem.attachment.length - 2 }} File(s)</span>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-row v-if="loadesFiles.length > 0">
                    <v-container fluid>
                      <h5>Current Attachments:</h5>
                      <li v-for="(attachment) in loadesFiles" :key="attachment.id">
                        <v-btn
                          class="ma-2"
                          color="primary"
                          dark
                        >
                          {{attachment.name}}
                          <v-icon
                            small
                            @click="downloadFile(attachment.id)"
                            title="Delete"
                          >mdi-download</v-icon>
                          </v-btn>
                          <v-icon
                            small
                            @click="deleteAttachment(item, attachment.id, index)"
                            title="Delete"
                          >mdi-delete</v-icon>
                        
                      </li> 
                    </v-container>
                  </v-row>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item, 'dialog')"
        v-b-tooltip.hover
        title="Edit"
      >mdi-pencil</v-icon>
      <v-icon
        small
        @click="deleteItem(item, items)"
        v-b-tooltip.hover
        title="Delete"
      >mdi-delete</v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="reset">Reset</v-btn>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length + 1">
        <div class="row justify-center">
          <div class="col-md-11">
            <div class="row mt-3">
              <div class="col-6">
                <v-container fluid>
                  <h5>Attachments:</h5>
                  <li v-for="(attachment, index) in item.attachments_list" :key="attachment.id">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      dark
                      @click="downloadFile(attachment.id)"
                    >
                      {{attachment.name}}
                      <v-icon
                        small
                        @click="downloadFile(attachment.id)"
                        title="Delete"
                      >mdi-download</v-icon>
                    </v-btn>
                    <v-icon
                      small
                      @click="deleteAttachment(item, attachment.id, index)"
                      title="Delete"
                    >mdi-delete</v-icon>
                  </li> 
                </v-container>
              </div>
            </div>
          </div>
        </div>
      </td>
    </template>
    <template slot="footer">
      <table>
        <tr class="border border-right-0 border-left-0 border-bottom-0 text-center">
          <td colspan>
            <v-chip color="lighten-1 mr-4">
              Items: &nbsp;
              <strong class="ml-2">{{ itemsFiltered }}</strong>
            </v-chip>
          </td>
        </tr>
      </table>
    </template>
  </v-data-table>
</template>

<script>
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri:"report",
    workOrdersList: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    search: "",
    popDatepick1: false,
    singleExpand: false,
    expanded: [],
    loadesFiles:[],
    editedIndex: -1,
    currentTmpFiles: [],
    currentId: "",
    editedItem: {
      id: "",
      date: new Date().toISOString().substr(0, 10),
      description: "",
      attachment: [],
      attachments_list: []
    },
    defaultItem: {
      id: "",
      date: new Date().toISOString().substr(0, 10),
      description: "",
      attachment: [],
      attachments_list: []
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      {text: "All", value: 99},
      {text: "id", value: 0},
      {text: "Work Order", value: 1},
      {text: "Description", value: 3}
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Report" : "Edit Report";
    },

    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },

    ...mapGetters([ 'currentUser' ])
  },

  props: ["headers","items", "search_tool", "current_wo_id"],

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    var _self = this;

    _self.currentId = _self.$route.params.reportid;
    if(_self.currentId != undefined && _self.currentId != "") {
      genericService.getRecord(_self.uri, _self.currentId, function(record){
        _self.currentItem = record;
        _self.editItem(_self.currentItem, 'dialog');
      });
      
    }
      
  },

  methods: {

    editItem(item, dialog) {
      this.getLoadesFiles(item);
      /* Listado de Dialogs */
      if (dialog == "dialog") {
        this.formNewtItem = false;
        this.dialog = true;
      }
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    getLoadesFiles(item) {
      var _self = this;
      
      genericService.getRelatedFiles(_self.uri, item.id, function(records){
        _self.loadesFiles = records.attachments_list
      });
    },

    deleteItem(item, list) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    deleteAttachment(item, id, index) {
      if(confirm("Are you sure you want to delete this attachment?")) {
        genericService.deleteItem('files', id);
        item.attachments_list.splice(index, 1);
        Swal.fire({
            title: "",
            text: "Record has been successfully deleted!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
      }
    },

    downloadFile(fileId) {
      genericService.getFile(fileId, function(fileContent){
        var a = document.createElement("a");
        a.href = fileContent.data;
        a.download = fileContent.name;
        a.click(); 
      });
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(items) {
      var _self = this;
      
      var tmpFiles = _self.editedItem.attachment;
      //_self.editedItem.attachment = "";
      //_self.editedItem.attachments_list = "";
      
      if (this.editedIndex > -1) {
        Object.assign(items[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });

          tmpFiles.forEach(attach => {
            var fileExtension = attach.type.split("/")[1];
            if(fileExtension == 'plain') {
              fileExtension="txt";
            }

            var reader = new FileReader();
            
            reader.onload = function() {
              var fileObject = {
                fileName:attach.name,
                fileType:attach.type,
                fileExtension:fileExtension,
                fileContent:reader.result,
              };

              genericService.createRecord("/" + _self.uri + "/" + _self.editedItem.id + "/files", fileObject, function(rta){
                _self.updateAttachment(rta, _self.editedItem);
              });    
            };

            reader.readAsDataURL(attach);
          });

        });
      } else {
        this.editedItem.work_order = this.current_wo_id;
        items.push(this.editedItem);

        genericService.createRecord("/"+this.uri, this.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            _self.editedItem.id = rta.id;

            tmpFiles.forEach(attach => {
              var fileExtension = attach.type.split("/")[1];
              if(fileExtension == 'plain') {
                fileExtension="txt";
              }

              var reader = new FileReader();
              
              reader.onload = function() {
                var fileObject = {
                  fileName:attach.name,
                  fileType:attach.type,
                  fileExtension:fileExtension,
                  fileContent:reader.result,
                };

                genericService.createRecord("/" + _self.uri + "/" + rta.id + "/files", fileObject, function(rta){
                  _self.updateAttachment(rta, _self.editedItem);
                });    
              };

              reader.readAsDataURL(attach);
            });


          }
        });
      }
      this.currentItems(items);
      this.close();
    },

    updateAttachment(rta, item) {
      if(rta != undefined) {
        if(location.hash.includes('reports')) {
          //go to reports edited item modal
          this.$router.push({ name: 'report-detail', params: { reportid: item.id } })
          location.href = '#/reports/' + item.id;
          location.reload();
        } else {
          //go to WO detail tab
          this.$router.push({ name: 'work-order-detail-tab', params: { id: this.current_wo_id, tab: 5 } })
          location.href = '#/work-orders/' + this.current_wo_id + '/5';
          location.reload();
        }
      }      
    },

    reset(){
      this.$emit('initialize');
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },
    filterItems(itemFiltered) {
      if (itemFiltered == 99 || itemFiltered == null) {
        this.headers.forEach(header => (header.filterable = true));
      } else {
        this.headers.forEach(header => (header.filterable = false));
        this.headers[itemFiltered].filterable = true;
      }
      this.dialogFilters = false;
      return this.headers;
    },
    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },
    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    formNewItem(){
      this.loadesFiles = [];
      this.editedItem.attachment = [];
      this.editedItem.attachments_list = [];
      
      this.formNewtItem = true;
      setTimeout(() => {
        this.focusField(this.$refs.formFirstField);
      }, 200)
    }
  },
  mounted() {
    var _self = this;

    genericService.getRecordList('/workorders/', function(records){
        _self.workOrdersList = records.reverse();
    });

    if(this.search_tool == true){
      this.focusField(this.$refs.search);
    }
  }
};
</script>
