<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <ReportsList
            :headers="headersReports"
            :items="itemsReports"
            @initialize="initialize"
            :search_tool="true"
          ></ReportsList>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ReportsList from "@/views/pages/reports/ReportsList.vue";
import genericService from "./../../../services/generic.service";


export default {
  components: {
    ReportsList,
  },

  data: () => ({
    uri:"report",
    headersReports: [
      { text: "ID", value: "id", sortable: false, filterable: true },
      { text: "Work Order", value: "work_order", sortable: false, filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsReports: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordList('/'+this.uri, function(records){
          _self.itemsReports = records
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Reports / Certificates" }]);
  },
};
</script>
